import { AppApi } from "../axios-config";

export interface IBearerToken {
    token: string;
}
export interface ILoginRequest {
    email: string;
    password: string;
}
export interface IAuthResponse {
    name: string;
    email: string;
    access: string;
    refresh: string;
}
export interface IPasswordForgotRequest {
    email: string;
}
export interface IPasswordResetRequest {
    passwordResetToken: string | null;
    email: string;
    newPassword: string;
}
export interface IPasswordChangeRequest {
    oldPassword: string;
    newPassword: string;
}

async function refreshAuth(): Promise<IAuthResponse> {
    const refreshToken = window.sessionStorage.getItem("refresh");
    return await AppApi.post(`/auth/refresh`, { refresh: refreshToken }).then(
        (response) => {
            window.sessionStorage.setItem("access", `${response.data.access}`);
            window.sessionStorage.setItem(
                "expires",
                `${Date.now() + 1000 * (response.data.timeOutLimit - 10)}`
            );
            return response.data;
        }
    );
}

async function login(body: ILoginRequest): Promise<IAuthResponse> {
    return await AppApi.post(`/auth/login`, body).then((response) => {
        window.sessionStorage.setItem("refresh", `${response.data.refresh}`);
        window.sessionStorage.setItem("access", `${response.data.access}`);
        window.sessionStorage.setItem(
            "expires",
            `${Date.now() + 1000 * (response.data.timeOutLimit - 10)}`
        );
        return response.data;
    });
}

async function logout(): Promise<void> {
    return await AppApi.post(`/auth/logout`).then((response) => {
        window.sessionStorage.removeItem("refresh");
        window.sessionStorage.removeItem("access");
        window.sessionStorage.removeItem("expires");
    });
}

async function requestReset(body: IPasswordForgotRequest): Promise<void> {
    return await AppApi.post(`/auth/password/forgot`, body).then(
        (response) => {}
    );
}

async function passwordReset(body: IPasswordResetRequest): Promise<void> {
    return await AppApi.post(`/auth/password/reset`, body).then(
        (response) => {}
    );
}

async function passwordChange(body: IPasswordChangeRequest): Promise<void> {
    return await AppApi.post(`/auth/password/change`, body).then(
        (response) => {}
    );
}

export const AuthService = {
    refreshAuth,
    login,
    logout,
    requestReset,
    passwordReset,
    passwordChange,
};
