import { Box, Button, Stack, Typography } from "@mui/material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { FallbackProps } from "react-error-boundary";
import { useAuthContext } from "../../contexts/AuthContext";
import { useEffect, useState } from "react";

export const SessionExpiredPage = (props: FallbackProps) => {
    const [secondsLeft, setSecondsLeft] = useState(10);
    const { resetErrorBoundary } = props;

    const { reset } = useAuthContext();

    useEffect(() => {
        reset();
        setTimeout(() => {
            resetErrorBoundary();
        }, 10000);
    });

    useEffect(() => {
        setTimeout(() => {
            setSecondsLeft(secondsLeft - 1);
        }, 1000);
    }, [secondsLeft]);

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <Stack alignItems="center">
                <AccessTimeOutlinedIcon sx={{ fontSize: 60, color: "red" }} />
                <Typography variant="h5">Session Expired</Typography>
                <Typography>Your session has expired.</Typography>
                <Typography>
                    You'll be redirected automatically in {secondsLeft}{" "}
                    seconds...
                </Typography>
                <Button onClick={resetErrorBoundary}>Refresh</Button>
            </Stack>
        </Box>
    );
};
