import { createTheme } from "@mui/material";

export const LightTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: 
                process.env.REACT_APP_CLIENT_NAME==='il2' ? "#10b9c2" 
                : process.env.REACT_APP_CLIENT_NAME==='ekeyid' ? "#1d71b8" 
                : "#10b9c2",
            light: 
                process.env.REACT_APP_CLIENT_NAME==='il2' ? "#12cdd7" 
                : process.env.REACT_APP_CLIENT_NAME==='ekeyid' ? "#abdafc" 
                : "#12cdd7",                
            dark: 
                process.env.REACT_APP_CLIENT_NAME==='il2' ? "#20495f" 
                : process.env.REACT_APP_CLIENT_NAME==='ekeyid' ? "#134770" 
                : "#20495f", 
            contrastText: 
                process.env.REACT_APP_CLIENT_NAME==='il2' ? "#eeeeee" 
                : process.env.REACT_APP_CLIENT_NAME==='ekeyid' ? "#eeeeee" 
                : "#eeeeee",                 
        },
        secondary: {
            main: 
                process.env.REACT_APP_CLIENT_NAME==='il2' ? "#60c8cd" 
                : process.env.REACT_APP_CLIENT_NAME==='ekeyid' ? "#30a8ff" 
                : "#60c8cd",
            light: 
                process.env.REACT_APP_CLIENT_NAME==='il2' ? "#e4e3e3" 
                : process.env.REACT_APP_CLIENT_NAME==='ekeyid' ? "#acdafc" 
                : "#e4e3e3",                
            dark: 
                process.env.REACT_APP_CLIENT_NAME==='il2' ? "#0b838a" 
                : process.env.REACT_APP_CLIENT_NAME==='ekeyid' ? "#000000" 
                : "#0b838a", 
            contrastText: 
                process.env.REACT_APP_CLIENT_NAME==='il2' ? "#eeeeee" 
                : process.env.REACT_APP_CLIENT_NAME==='ekeyid' ? "#eeeeee" 
                : "#eeeeee",   
        },        
        background: {
            paper: "#FFFFFF",
            default: "#FFFFFF",

        },
    },
    typography: {
        allVariants: {
            fontFamily: ["muli", "Segoe UI", "Roboto"].join(","),
        },
        button: {
            textTransform: "none",
        },
        fontWeightBold: 700,
    },
    components: {},
});
