let HOST_BASE_URL: string;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    //HOST_BASE_URL = "https://vault-test.il2.io/rest/ui/v1";
    HOST_BASE_URL = "http://localhost:8000/rest/ui/v1";
} else {
    HOST_BASE_URL = "/rest/ui/v1";
}

export const Environment = {
    TITLE: "Document Vault",

    // Cookie remember age to expire in seconds
    COOKIE_DEFAULT_MAX_AGE: 60 * 15,

    // Cookie remember age to expire in seconds
    COOKIE_REMEMBER_MAX_AGE: 60 * 60 * 24 * 1,

    // Maximum comment text length
    MAX_COMMNENT_LENGHT: 140,

    // Address to the IL2 Document Vault UI API
    HOST_BASE_URL: HOST_BASE_URL,
};
