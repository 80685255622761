import axios from "axios";
import { Environment } from "../../../environment";
import {
    errorInterceptor,
    requestInterceptor,
    responseInterceptor,
} from "./interceptors";

export const AppApi = axios.create({
    baseURL: Environment.HOST_BASE_URL,
    withCredentials: true,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

AppApi.interceptors.response.use(
    (response) => {
        return responseInterceptor(response);
    },
    (error) => errorInterceptor(error)
);

AppApi.interceptors.request.use(
    (request) => requestInterceptor(request),
    (error) => {
        return Promise.reject(error);
    }
);
