import { FallbackProps } from "react-error-boundary";
import { SessionExpiredPage } from "./SessionExpiredPage";
import { ErrorPage } from "./ErrorPage";

export const ErrorFallback = (props: FallbackProps) => {
    const { error } = props;

    if (error.message === "Session Expired") {
        return <SessionExpiredPage {...props} />;
    }

    return <ErrorPage {...props} />;
};
