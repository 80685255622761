import React from "react";
import "./App.css";
import { AppRoutes } from "./routes";
import { AuthProvider } from "./shared/contexts/AuthContext";
import CookieConsent from "react-cookie-consent";

function App() {
    return (
        <AuthProvider>
            <AppRoutes />
            <CookieConsent style={{ textAlign: "center" }}>
                This website uses cookies to enhance the user experience.
            </CookieConsent>
        </AuthProvider>
    );
}

export default App;
