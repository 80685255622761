import { createTheme } from "@mui/material";

export const DarkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: 
                process.env.REACT_APP_CLIENT_NAME==='il2' ? "#10b9c2" 
                : process.env.REACT_APP_CLIENT_NAME==='ekeyid' ? "#1d71b8" 
                : "#10b9c2",
            light: 
                process.env.REACT_APP_CLIENT_NAME==='il2' ? "#12cdd7" 
                : process.env.REACT_APP_CLIENT_NAME==='ekeyid' ? "#3495e5" 
                : "#12cdd7",  
            dark:
                process.env.REACT_APP_CLIENT_NAME==='il2' ? "#20495F" 
                : process.env.REACT_APP_CLIENT_NAME==='ekeyid' ? "#134770" 
                : "#20495F",   
            contrastText: 
                process.env.REACT_APP_CLIENT_NAME==='il2' ? "#eeeeee" 
                : process.env.REACT_APP_CLIENT_NAME==='ekeyid' ? "#eeeeee" 
                : "#eeeeee",   
        },
        secondary: {
            main: 
                process.env.REACT_APP_CLIENT_NAME==='il2' ? "#60c8cd" 
                : process.env.REACT_APP_CLIENT_NAME==='ekeyid' ? "#30a8ff" 
                : "#60c8cd",
            light: 
                process.env.REACT_APP_CLIENT_NAME==='il2' ? "#e4e3e3" 
                : process.env.REACT_APP_CLIENT_NAME==='ekeyid' ? "#acdafc" 
                : "#e4e3e3",                
            dark: 
                process.env.REACT_APP_CLIENT_NAME==='il2' ? "#ffffff" 
                : process.env.REACT_APP_CLIENT_NAME==='ekeyid' ? "#ffffff" 
                : "#ffffff", 
            contrastText: 
                process.env.REACT_APP_CLIENT_NAME==='il2' ? "#eeeeee" 
                : process.env.REACT_APP_CLIENT_NAME==='ekeyid' ? "#eeeeee" 
                : "#eeeeee",   
        },   
        background: {
            paper: "#353535",
            default: 
                process.env.REACT_APP_CLIENT_NAME==='il2' ? "#353535" 
                : process.env.REACT_APP_CLIENT_NAME==='ekeyid' ? "#353535"
                : "#353535",
        },
    },
    typography: {
        allVariants: {
            fontFamily: ["muli", "Segoe UI", "Roboto"].join(","),
            color: "white",
        },
        button: {
            textTransform: "none",
        },
        fontWeightBold: 700,
    },
});
