import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { ILoginRequest, AuthService } from "../services/api/auth/AuthService";

interface IAuthContextData {
    isAuthenticated: boolean;
    name: string | undefined;
    email: string | undefined;
    login: (body: ILoginRequest) => Promise<void>;
    logout: () => void;
    reset: () => void;
}

interface IAuthProviderProps {
    children: React.ReactNode;
}

const AuthContext = createContext({} as IAuthContextData);

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
    const [name, setName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [isAuth, setIsAuth] = useState<boolean>(false);

    const setLocalStates = (
        name: string | undefined,
        email: string | undefined,
        isAuth: boolean
    ) => {
        setName(name);
        setEmail(email);
        setIsAuth(isAuth);
    };

    useEffect(() => {
        AuthService.refreshAuth()
            .then((data) => {
                setLocalStates(data.name, data.email, true);
            })
            .catch((error) => {
                setLocalStates(undefined, undefined, false);
            });
    }, []);

    const handleLogin = useCallback(async (body: ILoginRequest) => {
        setLocalStates(undefined, undefined, false);
        await AuthService.login(body).then((data) => {
            setLocalStates(data.name, data.email, true);
        });
    }, []);

    const handleLogout = useCallback(async () => {
        await AuthService.logout();
        setLocalStates(undefined, undefined, false);
    }, []);

    const resetLocal = useCallback(() => {
        setLocalStates(undefined, undefined, false);
    }, []);

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated: isAuth,
                name: name,
                email: email,
                login: handleLogin,
                logout: handleLogout,
                reset: resetLocal,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);
