import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "./shared/services";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./shared/components/errors/ErrorFallback";
import { AppThemeProvider } from "./shared/contexts/AppThemeContext";
import { CssBaseline } from "@mui/material";

import "./lib/i18n";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <AppThemeProvider>
                <CssBaseline />
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <App />
                    <ReactQueryDevtools
                        initialIsOpen={false}
                        position="bottom-left"
                    />
                </ErrorBoundary>
            </AppThemeProvider>
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
