import { RouterProvider, createBrowserRouter } from "react-router-dom";
//import { Home } from "../pages/home/Home";
//import { DocumentVault } from "../pages/document-vault/DocumentVault";
//import { PageNotFound } from "../shared/components/page-not-found/PageNotFound";
//import { PrivateRoute } from "./PrivateRoute";
//import { LandingPageLayout } from "../layouts/LandingPageLayout";
//import { LoginPage } from "../shared/components/login/LoginPage";
import { Suspense, lazy } from "react";
import { CenteredCircularProgress } from "../shared/utils/CenteredCircularProgress";

const LazyHome = lazy(() =>
    import("../pages/home/Home").then(({ Home }) => ({ default: Home }))
);
const LazyLearnMore = lazy(() =>
    import("../pages/learn-more/LearnMore").then(({ LearnMore }) => ({
        default: LearnMore,
    }))
);
const LazyDocumentVault = lazy(() =>
    import("../pages/document-vault/DocumentVault").then(
        ({ DocumentVault }) => ({ default: DocumentVault })
    )
);
const LazyPrivateRoute = lazy(() =>
    import("./PrivateRoute").then(({ PrivateRoute }) => ({
        default: PrivateRoute,
    }))
);
const LazyPageNotFound = lazy(() =>
    import("../shared/components/errors/PageNotFound").then(
        ({ PageNotFound }) => ({
            default: PageNotFound,
        })
    )
);
const LazyLandingPageLayout = lazy(() =>
    import("../layouts/LandingPageLayout").then(({ LandingPageLayout }) => ({
        default: LandingPageLayout,
    }))
);
const LazyLoginPage = lazy(() =>
    import("../pages/login/LoginPage").then(({ LoginPage }) => ({
        default: LoginPage,
    }))
);
const LazyPasswordResetPage = lazy(() =>
    import("../pages/password-reset/PasswordResetPage").then(
        ({ PasswordResetPage }) => ({
            default: PasswordResetPage,
        })
    )
);
const LazyPasswordChangePage = lazy(() =>
    import("../pages/profile/password-change/PasswordChangePage").then(
        ({ PasswordChangePage }) => ({
            default: PasswordChangePage,
        })
    )
);
const LazyErroBoundaryLayout = lazy(() =>
    import("../layouts/ErrorBoundaryLayout").then(
        ({ ErrorBoundaryLayout }) => ({
            default: ErrorBoundaryLayout,
        })
    )
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <LazyErroBoundaryLayout />,
        children: [
            {
                path: "/",
                element: (
                    <Suspense fallback=<CenteredCircularProgress />>
                        <LazyLandingPageLayout />
                    </Suspense>
                ),
                children: [
                    {
                        path: "",
                        element: <LazyHome />,
                    },
                    /*
                    {
                        path: "learn",
                        element: <LazyLearnMore />,
                    },
                    */
                    {
                        path: "password-reset",
                        element: <LazyPasswordResetPage />,
                    },
                    {
                        path: "",
                        element: <LazyPrivateRoute />,
                        children: [
                            {
                                path: "documents",
                                element: <LazyDocumentVault />,
                            },
                            {
                                path: "user/password-change",
                                element: <LazyPasswordChangePage />,
                            },
                        ],
                    },
                    {
                        path: "*",
                        element: <LazyPageNotFound />,
                    },
                ],
            },
            {
                path: "login",
                element: (
                    <Suspense fallback=<CenteredCircularProgress />>
                        <LazyLoginPage />
                    </Suspense>
                ),
            },
        ],
    },
]);

export const AppRoutes = () => {
    return <RouterProvider router={router} />;
};
