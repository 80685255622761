import { AxiosError } from "axios";
import { Utils } from "../../../utils";
import { AuthService } from "../auth/AuthService";

function handleDates(body: any) {
    const { isIsoDateString } = Utils;
    if (body === null || body === undefined || typeof body !== "object") return;

    for (const key of Object.keys(body)) {
        const value = body[key];

        if (isIsoDateString(value)) {
            body[key] = new Date(value);
        } else if (typeof value === "object") handleDates(value);
    }
}

export async function requestInterceptor(request: any) {
    const expires = Number(window.sessionStorage.getItem("expires"));
    const refresh = window.sessionStorage.getItem("refresh");
    if (
        refresh &&
        expires &&
        request.url !== "/auth/refresh" &&
        Date.now() > expires
    ) {
        await AuthService.refreshAuth();
    }
    const bearerToken = window.sessionStorage.getItem("access");
    if (bearerToken) request.headers["Authorization"] = `Bearer ${bearerToken}`;
    return request;
}

export const responseInterceptor = (response: any) => {
    handleDates(response.data);
    return response;
};

export const errorInterceptor = (error: AxiosError) => {
    if (error.message === "Network Error") {
        return Promise.reject(new Error("Network error."));
    }
    if (
        !error?.request?.responseURL.endsWith("/auth/login") &&
        error?.response?.status === 401
    ) {
        window.sessionStorage.removeItem("expires");
        window.sessionStorage.removeItem("access");
        window.sessionStorage.removeItem("refresh");
        return Promise.reject(new Error("Session Expired"));
    }

    return Promise.reject(error);
};
