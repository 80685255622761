import {
    ImageOutlined,
    InsertDriveFileOutlined,
    PictureAsPdfOutlined,
} from "@mui/icons-material";

function isIsoDateString(value: any): boolean {
    const isoDateFormat =
        /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d*)?(Z|[+-]\d{2}(:?\d{2})?)?)?$/;
    return value && typeof value === "string" && isoDateFormat.test(value);
}

function getIconByMime(mimeType: string) {
    if (mimeType.includes("image")) return <ImageOutlined />;
    if (mimeType.includes("pdf")) return <PictureAsPdfOutlined />;
    return <InsertDriveFileOutlined />;
}

function formatFolderFilename(path: string, filename: string) {
    if (path.at(-1) !== "/") path = path + "/";
    if (path.length === 1) return filename;
    return path + filename;
}

async function parseJSONBlob(data: any) {
    const isJsonBlob = (data: any) =>
        data instanceof Blob && data.type === "application/json";
    const responseData = isJsonBlob(data) ? await data?.text() : data || {};
    const responseJson =
        typeof responseData === "string"
            ? JSON.parse(responseData)
            : responseData;
    return responseJson;
}

function parsePasswordError(message: string) {
    const pattern = /Weak password: ([^']+)/;
    const match = pattern.exec(message);
    if (match) return match[1];
    return undefined;
}

export const Utils = {
    isIsoDateString,
    getIconByMime,
    formatFolderFilename,
    parseJSONBlob,
    parsePasswordError,
};
